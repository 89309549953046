<template>
  <ErrorPage
    :title="
      te(`${cause}.statusCode`) ? t(`${cause}.statusCode`) : `${statusCode}`
    "
    :description="
      te(`${cause}.description`) ? t(`${cause}.description`) : description
    "
  />
</template>

<script lang="ts" setup>
const { t, te } = useI18n()

const props = defineProps<{
  statusCode: number
  statusMessage?: string
  description?: string
  data?: unknown
  cause?: unknown
  url?: string
}>()

const description = computed(() => props.description || props.statusMessage)
</script>

<i18n lang="json">
{
  "en": {
    "access-denied": {
      "statusCode": "Access denied"
    },
    "sso-process-cancelled-by-user": {
      "statusCode": "Login cancelled",
      "description": "Login process cancelled"
    }
  },
  "es": {
    "access-denied": {
      "statusCode": "Access denied"
    },
    "sso-process-cancelled-by-user": {
      "statusCode": "Login cancelled",
      "description": "Login process cancelled"
    }
  }
}
</i18n>

import { default as _91_46_46_46slug_930Z4H74bcW0Meta } from "/vercel/path0/apps/unity.org/app/pages/[...slug].vue?macro=true";
import { default as experienceakFsi4dkIEMeta } from "/vercel/path0/apps/unity.org/app/pages/experience.vue?macro=true";
import { default as growqqiiKdg9n2Meta } from "/vercel/path0/apps/unity.org/app/pages/grow.vue?macro=true";
import { default as indexNlmBi5XESqMeta } from "/vercel/path0/apps/unity.org/app/pages/index.vue?macro=true";
import { default as oopsn6wElPFadtMeta } from "/vercel/path0/apps/unity.org/app/pages/oops.vue?macro=true";
import { default as _91uuid_9312YM25EIwdMeta } from "/vercel/path0/apps/unity.org/app/pages/person/[uuid].vue?macro=true";
import { default as bible_45interpretationsLPmIW7S7KbMeta } from "/vercel/path0/apps/unity.org/app/pages/search/bible-interpretations.vue?macro=true";
import { default as daily_45wordKowNVXhWGRMeta } from "/vercel/path0/apps/unity.org/app/pages/search/daily-word.vue?macro=true";
import { default as indexLweTMf5egxMeta } from "/vercel/path0/apps/unity.org/app/pages/search/index.vue?macro=true";
import { default as productsEMudHAiG2xMeta } from "/vercel/path0/apps/unity.org/app/pages/search/products.vue?macro=true";
import { default as shopOjsJtOmDJAMeta } from "/vercel/path0/apps/unity.org/app/pages/shop.vue?macro=true";
import { default as trigger_45errorvuHnr7ffq8Meta } from "/vercel/path0/apps/unity.org/app/pages/tester/trigger-error.vue?macro=true";
export default [
  {
    name: "slug___en___default",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_930Z4H74bcW0Meta || {},
    component: () => import("/vercel/path0/apps/unity.org/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    meta: _91_46_46_46slug_930Z4H74bcW0Meta || {},
    component: () => import("/vercel/path0/apps/unity.org/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___es",
    path: "/es/:slug(.*)*",
    meta: _91_46_46_46slug_930Z4H74bcW0Meta || {},
    component: () => import("/vercel/path0/apps/unity.org/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "experience___en___default",
    path: "/experience",
    meta: experienceakFsi4dkIEMeta || {},
    component: () => import("/vercel/path0/apps/unity.org/app/pages/experience.vue").then(m => m.default || m)
  },
  {
    name: "experience___en",
    path: "/en/experience",
    meta: experienceakFsi4dkIEMeta || {},
    component: () => import("/vercel/path0/apps/unity.org/app/pages/experience.vue").then(m => m.default || m)
  },
  {
    name: "experience___es",
    path: "/es/experiencia",
    meta: experienceakFsi4dkIEMeta || {},
    component: () => import("/vercel/path0/apps/unity.org/app/pages/experience.vue").then(m => m.default || m)
  },
  {
    name: "grow___en___default",
    path: "/grow",
    meta: growqqiiKdg9n2Meta || {},
    component: () => import("/vercel/path0/apps/unity.org/app/pages/grow.vue").then(m => m.default || m)
  },
  {
    name: "grow___en",
    path: "/en/grow",
    meta: growqqiiKdg9n2Meta || {},
    component: () => import("/vercel/path0/apps/unity.org/app/pages/grow.vue").then(m => m.default || m)
  },
  {
    name: "grow___es",
    path: "/es/crecer",
    meta: growqqiiKdg9n2Meta || {},
    component: () => import("/vercel/path0/apps/unity.org/app/pages/grow.vue").then(m => m.default || m)
  },
  {
    name: "index___en___default",
    path: "/",
    meta: indexNlmBi5XESqMeta || {},
    component: () => import("/vercel/path0/apps/unity.org/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexNlmBi5XESqMeta || {},
    component: () => import("/vercel/path0/apps/unity.org/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    meta: indexNlmBi5XESqMeta || {},
    component: () => import("/vercel/path0/apps/unity.org/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "oops___en___default",
    path: "/oops",
    component: () => import("/vercel/path0/apps/unity.org/app/pages/oops.vue").then(m => m.default || m)
  },
  {
    name: "oops___en",
    path: "/en/oops",
    component: () => import("/vercel/path0/apps/unity.org/app/pages/oops.vue").then(m => m.default || m)
  },
  {
    name: "oops___es",
    path: "/es/oops",
    component: () => import("/vercel/path0/apps/unity.org/app/pages/oops.vue").then(m => m.default || m)
  },
  {
    name: "person-uuid___en___default",
    path: "/person/:uuid()",
    component: () => import("/vercel/path0/apps/unity.org/app/pages/person/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "person-uuid___en",
    path: "/en/person/:uuid()",
    component: () => import("/vercel/path0/apps/unity.org/app/pages/person/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "person-uuid___es",
    path: "/es/person/:uuid()",
    component: () => import("/vercel/path0/apps/unity.org/app/pages/person/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "search-bible-interpretations___en___default",
    path: "/search/bible-interpretations",
    meta: bible_45interpretationsLPmIW7S7KbMeta || {},
    component: () => import("/vercel/path0/apps/unity.org/app/pages/search/bible-interpretations.vue").then(m => m.default || m)
  },
  {
    name: "search-bible-interpretations___en",
    path: "/en/search/bible-interpretations",
    meta: bible_45interpretationsLPmIW7S7KbMeta || {},
    component: () => import("/vercel/path0/apps/unity.org/app/pages/search/bible-interpretations.vue").then(m => m.default || m)
  },
  {
    name: "search-bible-interpretations___es",
    path: "/es/buscar/interpretaciones",
    meta: bible_45interpretationsLPmIW7S7KbMeta || {},
    component: () => import("/vercel/path0/apps/unity.org/app/pages/search/bible-interpretations.vue").then(m => m.default || m)
  },
  {
    name: "search-daily-word___en___default",
    path: "/search/daily-word",
    meta: daily_45wordKowNVXhWGRMeta || {},
    component: () => import("/vercel/path0/apps/unity.org/app/pages/search/daily-word.vue").then(m => m.default || m)
  },
  {
    name: "search-daily-word___en",
    path: "/en/search/daily-word",
    meta: daily_45wordKowNVXhWGRMeta || {},
    component: () => import("/vercel/path0/apps/unity.org/app/pages/search/daily-word.vue").then(m => m.default || m)
  },
  {
    name: "search-daily-word___es",
    path: "/es/buscar/la-palabra-diaria",
    meta: daily_45wordKowNVXhWGRMeta || {},
    component: () => import("/vercel/path0/apps/unity.org/app/pages/search/daily-word.vue").then(m => m.default || m)
  },
  {
    name: "search___en___default",
    path: "/search",
    meta: indexLweTMf5egxMeta || {},
    component: () => import("/vercel/path0/apps/unity.org/app/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/en/search",
    meta: indexLweTMf5egxMeta || {},
    component: () => import("/vercel/path0/apps/unity.org/app/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/es/buscar",
    meta: indexLweTMf5egxMeta || {},
    component: () => import("/vercel/path0/apps/unity.org/app/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search-products___en___default",
    path: "/search/products",
    meta: productsEMudHAiG2xMeta || {},
    component: () => import("/vercel/path0/apps/unity.org/app/pages/search/products.vue").then(m => m.default || m)
  },
  {
    name: "search-products___en",
    path: "/en/search/products",
    meta: productsEMudHAiG2xMeta || {},
    component: () => import("/vercel/path0/apps/unity.org/app/pages/search/products.vue").then(m => m.default || m)
  },
  {
    name: "search-products___es",
    path: "/es/buscar/products",
    meta: productsEMudHAiG2xMeta || {},
    component: () => import("/vercel/path0/apps/unity.org/app/pages/search/products.vue").then(m => m.default || m)
  },
  {
    name: "shop___en___default",
    path: "/shop",
    meta: shopOjsJtOmDJAMeta || {},
    component: () => import("/vercel/path0/apps/unity.org/app/pages/shop.vue").then(m => m.default || m)
  },
  {
    name: "shop___en",
    path: "/en/shop",
    meta: shopOjsJtOmDJAMeta || {},
    component: () => import("/vercel/path0/apps/unity.org/app/pages/shop.vue").then(m => m.default || m)
  },
  {
    name: "shop___es",
    path: "/es/tienda",
    meta: shopOjsJtOmDJAMeta || {},
    component: () => import("/vercel/path0/apps/unity.org/app/pages/shop.vue").then(m => m.default || m)
  },
  {
    name: "tester-trigger-error___en___default",
    path: "/tester/trigger-error",
    component: () => import("/vercel/path0/apps/unity.org/app/pages/tester/trigger-error.vue").then(m => m.default || m)
  },
  {
    name: "tester-trigger-error___en",
    path: "/en/tester/trigger-error",
    component: () => import("/vercel/path0/apps/unity.org/app/pages/tester/trigger-error.vue").then(m => m.default || m)
  },
  {
    name: "tester-trigger-error___es",
    path: "/es/tester/trigger-error",
    component: () => import("/vercel/path0/apps/unity.org/app/pages/tester/trigger-error.vue").then(m => m.default || m)
  }
]
export default defineAppConfig({
  unityApi: {
    name: 'Hello from Unity API layer',
  },
})

declare module '@nuxt/schema' {
  interface AppConfigInput {
    unityApi?: {
      /** Project name */
      name?: string
    }
  }
}

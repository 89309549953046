import { defineNuxtPlugin } from '#app/nuxt'
import { LazyCardBasic, LazyCardBasicSkeleton, LazyCardImage, LazyCardPromotion, LazyCard, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["CardBasic", LazyCardBasic],
["CardBasicSkeleton", LazyCardBasicSkeleton],
["CardImage", LazyCardImage],
["CardPromotion", LazyCardPromotion],
["Card", LazyCard],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})

import * as logger from '~/utils/logging'

const MAGAZINES = [
  'daily-word',
  'la-palabra-diaria',
  'spirituality-and-health',
  'unity-magazine',
] as const

type Mag = (typeof MAGAZINES)[number]

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (!to.path.startsWith('/goto/')) {
    return
  }

  const slug = to.path.split('/').filter(Boolean).slice(1)
  const mag = slug.at(0) as Mag
  if (!MAGAZINES.includes(mag)) {
    return
  }

  try {
    const { url } = await $fetch(`/api/data/${String(mag)}/current`)

    if (!url) {
      throw createError({
        statusCode: 404,
        statusMessage: 'Page not found',
        cause: 'magazine-not-found',
      })
    }

    logger.log(`Redirecting to current \`${String(mag)}\` at "${url}"`)

    return navigateTo(url)
  } catch (error) {
    logger.error(`[goto] ${mag}`, error)
    throw createError({
      statusCode: 404,
      statusMessage: 'Page not found',
      cause: 'magazine-not-found',
      data: error,
    })
  }
})

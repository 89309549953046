<script setup lang="ts">
import * as logger from '~/utils/logging'
const { t, te } = useI18n()

const props = defineProps<{
  statusCode: number
  statusMessage?: string
  data?: unknown
  cause?: unknown
  url?: string
}>()

const handleError = (url: string) => {
  clearError({ redirect: url })
}

const url = computed(() => {
  if (import.meta.client) {
    return window.location.href
  }
  return props.url
})

const cause = computed(() => {
  const defaultCause = import.meta.server ? 'server-error' : 'client-error'

  if (!props?.cause) {
    logger.warn('[error] cause not provided:', props)
    return defaultCause
  }

  if (typeof props.cause !== 'string') {
    logger.warn('[error] cause is not a string:', props.cause)
    return defaultCause
  }

  return props.cause
})

const footnotes = computed(() => {
  const _url = unref(url)
  const _cause = unref(cause)
  return [
    _url && `Request Url: ${url.value}`,
    `Status Code: ${props.statusCode}`,
    _cause && `Cause: ${cause.value}`,
  ].filter(Boolean) as string[]
})
</script>

<template>
  <ErrorPage :footnotes="footnotes">
    <template #title>{{ t(`${cause}.heading`) }}</template>
    <template #description>
      {{ t(`${cause}.description`) }}
    </template>
    <template #actions>
      <template v-for="idx in 2" :key="idx">
        <button
          v-if="te(`${cause}.actions.${idx}.url`)"
          class="button"
          @click="handleError(t(`${cause}.actions.${idx}.url`))"
        >
          {{ t(`${cause}.actions.${idx}.label`) }}
        </button>
      </template>
    </template>
  </ErrorPage>
</template>

<i18n lang="json">
{
  "en": {
    "empty-data-response": {
      "heading": "Page is temporarily unavailable. Please try again later.",
      "description": "But don't worry, we'll get you back to more great Unity content.",
      "actions": {
        "1": {
          "label": "Back to unity.org",
          "url": "/"
        }
      }
    },
    "client-error": {
      "heading": "Page is temporarily unavailable. Please try again later.",
      "description": "But don't worry, we'll get you back to more great Unity content.",
      "actions": {
        "1": {
          "label": "Back to unity.org",
          "url": "/"
        }
      }
    },
    "server-error": {
      "heading": "Page is temporarily unavailable. Please try again later.",
      "description": "But don't worry, we'll get you back to more great Unity content.",
      "actions": {
        "1": {
          "label": "Back to unity.org",
          "url": "/"
        }
      }
    }
  },
  "es": {
    "empty-data-response": {
      "heading": "La página no está disponible temporalmente. Inténtelo de nuevo más tarde.",
      "description": "Pero no te preocupes, te llevaremos a más contenido excelente de Unity.",
      "actions": {
        "1": {
          "label": "Regresar a unity.org",
          "url": "/es"
        }
      }
    },
    "client-error": {
      "heading": "La página no está disponible temporalmente. Inténtelo de nuevo más tarde.",
      "description": "Pero no te preocupes, te llevaremos a más contenido excelente de Unity.",
      "actions": {
        "1": {
          "label": "Regresar a unity.org",
          "url": "/es"
        }
      }
    },
    "server-error": {
      "heading": "La página no está disponible temporalmente. Inténtelo de nuevo más tarde.",
      "description": "Pero no te preocupes, te llevaremos a más contenido excelente de Unity.",
      "actions": {
        "1": {
          "label": "Regresar a unity.org",
          "url": "/es"
        }
      }
    }
  }
}
</i18n>

import { pascal, mapKeys, title } from 'radash'
import { useEventListener } from '@vueuse/core'
import * as logger from '~/utils/logging'

const getClickTarget = (event: Event) => {
  if (!event?.target) return

  const target = event.target
  if (!(target instanceof Element)) return
  return target.tagName.toLowerCase() === 'a' ? target : target.closest('a')
}

const buildQuerySelector = () => {
  const schemes = ['http', 'https']
  const domains = [
    'shop.unity.org',
    'compra.unity.org',
    'shop.unityonline.org',
    'compra.unityonline.org',
  ]

  const selectors = []
  for (const domain of domains) {
    for (const scheme of schemes) {
      selectors.push(`${scheme}://${domain}`)
    }
  }

  return selectors.map((x) => `a[href^="${x}"]`).join(', ')
}

const QUERY_SELECTOR = buildQuerySelector()

const isWantedEventTarget = (element: Element) => {
  if (!(element instanceof HTMLAnchorElement)) return false

  return element.matches(QUERY_SELECTOR)
}

const shouldIgnoreClickEvent = (element: Element) => {
  // ignore links within promotion cards
  if (element.closest('.lyt-card-promotion')) {
    logger.info(
      '[PromotionCode] Click within promotion card. Not applying promotion code',
    )
    return true
  }
  return false
}

function onClick(event: Event, promotionCode?: string) {
  if (!promotionCode) return

  if (!event?.target) return

  const element = getClickTarget(event)
  if (!element) return

  if (!isWantedEventTarget(element)) return
  if (shouldIgnoreClickEvent(element)) return

  event.preventDefault()

  const href = element.getAttribute('href')
  if (!href) return

  const target = element.getAttribute('target') || '_blank'

  const url = new URL(href)
  const existingPromoCode = url.searchParams.get('PromotionCode')

  // set stored promo code
  url.searchParams.set('PromotionCode', promotionCode)

  if (existingPromoCode) {
    logger.warn(
      `[PromotionCode] Replacing exiting code "%s" with inbound code "%s"`,
      existingPromoCode,
      promotionCode,
    )
  }

  logger.info(
    '[PromotionCode] Applying promotion code "%s" to outboud url "%s"',
    promotionCode,
    href,
  )

  window.open(url.toString(), target)
}

const getPromotionCodeFromRoute = (route: ReturnType<typeof useRoute>) => {
  const query = mapKeys({ ...route.query }, (key) => pascal(title(String(key))))
  logger.log('[PromotionCode] getPromotionCodeFromRoute', { query, route })
  const { PromotionCode: code } = query
  if (!code) return

  return String(code)
}

export default defineNuxtPlugin(() => {
  if (import.meta.server) return

  const { currentRoute: route } = useRouter()
  // @note: useState resets when the app is hard loaded
  //        we can use useSessionStorage from '@vueuse/core' to persist the value
  const promotionCode = useState<string | undefined>('PromotionCode')

  watch(
    route,
    () => {
      const code = getPromotionCodeFromRoute(unref(route))
      if (!code) return

      logger.log(`[PromotionCode] setting promotion code to ${code} from route`)

      promotionCode.value = code
    },
    {
      immediate: true,
    },
  )

  // attach click handlers
  useEventListener(document, 'click', (event) => {
    const code = unref(promotionCode)
    logger.log('[PromotionCode] click', {
      route: unref(route),
      promotionCode: code,
    })
    onClick(event, code)
  })

  logger.debug('[PromotionCode] plugin loaded', {
    promotionCode: unref(promotionCode),
  })
})

export default defineAppConfig({
  sentry: {
    client: {
      tracesSampleRate: 0.2,
      replaysSessionSampleRate: 0.2,
      replaysOnErrorSampleRate: 0.2,
      tracePropagationTargets: ['localhost'],
    },
    server: {
      tracesSampleRate: 0.2,
      profilesSampleRate: 0.5,
    },
  },
})

declare module '@nuxt/schema' {
  type SentryConfig = {
    tracesSampleRate: number
    profilesSampleRate: number
    replaysSessionSampleRate: number
    replaysOnErrorSampleRate: number
    tracePropagationTargets: string[]
  }

  interface AppConfigInput {
    /** Sentry configuration */
    sentry?: {
      client: Partial<SentryConfig>
      server: Partial<SentryConfig>
    }
  }
}

import { defu } from 'defu'
import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const { sentry: _config } = useAppConfig()
  const router = useRouter()
  const {
    public: { sentry },
  } = useRuntimeConfig()

  if (!sentry?.dsn) {
    console.warn('Sentry DSN not set, skipping Sentry initialization')
    return
  }

  const defaultConfig = {
    // Configure this whole part as you need it!
    tracesSampleRate: 0.2, // Change in prod

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost'],

    replaysSessionSampleRate: 0.2, // Change in prod
    replaysOnErrorSampleRate: 0.2, // Change in prod if necessary
  }

  const config = defu(defaultConfig, _config.client)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if ((config as any)?.disable) {
    return
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],

    ...config,
  })
})

<template>
  <ErrorPage :footnotes="footnotes">
    <template #title>{{ t(`${cause}.heading`) }}</template>
    <template #description>
      {{ t(`${cause}.description`) }}
    </template>
    <template #actions>
      <template v-for="idx in 2" :key="idx">
        <button
          v-if="te(`${cause}.actions.${idx}.url`)"
          class="button"
          @click="handleError(t(`${cause}.actions.${idx}.url`))"
        >
          {{ t(`${cause}.actions.${idx}.label`) }}
        </button>
      </template>
    </template>
  </ErrorPage>
</template>

<script lang="ts" setup>
import * as logger from '~/utils/logging'
const { t, te } = useI18n()

const props = defineProps<{
  statusCode: number
  statusMessage?: string
  data?: unknown
  cause?: unknown
  url?: string
}>()

const handleError = (url: string) => {
  clearError({ redirect: url })
}

const url = computed(() => {
  if (import.meta.client) {
    return window.location.href
  }
  return props.url
})

const cause = computed(() => {
  if (!props?.cause) {
    logger.warn('[error] cause not provided:', props)
    return 'page-not-found'
  }

  if (typeof props.cause !== 'string') {
    logger.warn('[error] cause is not a string:', props.cause)
    return 'page-not-found'
  }

  return props.cause
})

const footnotes = computed(() => {
  const _url = unref(url)
  const _cause = unref(cause)
  return [
    _url && `Request Url: ${url.value}`,
    `Status Code: ${props.statusCode}`,
    _cause && `Cause: ${cause.value}`,
  ].filter(Boolean) as string[]
})
</script>

<i18n lang="json">
{
  "en": {
    "page-not-found": {
      "heading": "Feeling lost? You're not alone. Unity is here for you.",
      "description": "Enjoy the latest news and updates from Unity, great content worth looking for.",
      "actions": {
        "1": {
          "label": "Back to unity.org",
          "url": "/"
        }
      }
    },
    "translation-not-found": {
      "heading": "Lost in Translation? The content you're looking for is not available in another language.",
      "description": "But don't worry, select your preferred language and we'll get you back to more great Unity content.",
      "actions": {
        "1": {
          "label": "unity.org (English)",
          "url": "/"
        },
        "2": {
          "label": "Unity.org/es (Español)",
          "url": "/es"
        }
      }
    }
  },
  "es": {
    "page-not-found": {
      "heading": "¿Te sientes perdido? Tú no estás solo. Unity está aquí para ti.",
      "description": "Disfruta de las noticias y actualizaciones más recientes de Unity, excelente contenido que vale la pena buscar.",
      "actions": {
        "1": {
          "label": "Regresar a unity.org",
          "url": "/es"
        }
      }
    },
    "translation-not-found": {
      "heading": "¿Confundido con la traducción? El contenido que buscas no está disponible en otro idioma.",
      "description": "Pero no te preocupes, selecciona el idioma que deseas a continuación y con mucho gusto te guiaremos a más contenido excelente de Unity.",
      "actions": {
        "1": {
          "label": "unity.org (English)",
          "url": "/"
        },
        "2": {
          "label": "Unity.org/es (Español)",
          "url": "/es"
        }
      }
    }
  }
}
</i18n>

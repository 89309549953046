<template>
  <NuxtPwaManifest />
  <NuxtPwaAssets />
  <NuxtLoadingIndicator />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
const { t } = useI18n()
const i18nHead = useLocaleHead()

useHead({
  titleTemplate: '%s %separator %siteName',
  templateParams: { separator: '|', siteName: 'Unity' },
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs!.lang || 'en',
  },
  link: [
    ...(i18nHead.value.link || []),
    {
      rel: 'icon',
      type: 'image/png',
      href: '/favicon-32x32.png',
    },
  ],
  meta: [...(i18nHead.value.meta || [])],
})

useSeoMeta({
  ogSiteName: t('Unity'),
})
</script>

type ThemeDef = {
  readonly key: 'light' | 'dark' | 'auto'
  readonly icon: string
}

const themes: readonly ThemeDef[] = [
  {
    key: 'auto',
    icon: 'material-symbols:computer-outline',
  },
  {
    key: 'dark',
    icon: 'material-symbols:dark-mode-outline',
  },
  {
    key: 'light',
    icon: 'material-symbols:light-mode-outline',
  },
] as const

export default defineAppConfig({
  datePicker: {
    minDate: '1924-07-01',
    maxDate: '2024-06-01',
  },
  dailyWordSubscribeUrl: {
    en: 'https://shop.unityonline.org/subscribe?plan=Digital&PromotionCode=PP346001&GiftPromotionCode=PP346001',
    es: 'https://compra.unityonline.org/subscribe?Plan=Digital&PromotionCode=PP346002&GiftPromotionCode=PP346002',
  },
  sentry: {
    client: {
      tracesSampleRate: 0.2,
      replaysSessionSampleRate: 0.05,
      replaysOnErrorSampleRate: 0.1,
      tracePropagationTargets: ['localhost', 'api.unity.org', 'www.unity.org'],
    },
    server: {
      tracesSampleRate: 0.2,
      profilesSampleRate: 0.5,
    },
  },
  themes,
})

declare module '@nuxt/schema' {
  interface AppConfigInput {
    /** Theme configuration */
    themes?: typeof themes
  }
}

import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const route = useRoute()

  const meta = () => ({
    'meta.client': import.meta.client ? 'true' : 'false',
    'meta.browser': import.meta.browser ? 'true' : 'false',
    'meta.server': import.meta.server ? 'true' : 'false',
    'meta.nitro': import.meta.nitro ? 'true' : 'false',
    'meta.dev': import.meta.dev ? 'true' : 'false',
    'meta.test': import.meta.test ? 'true' : 'false',
    'meta.prerender': import.meta.prerender ? 'true' : 'false',
  })

  nuxtApp.hook('app:error', (error) => {
    const _meta = meta()
    if (import.meta.dev) {
      console.error('app:error', { error, _meta })
    }

    // report to sentry
    Sentry.captureException(error, {
      extra: {
        'nuxt.version': nuxtApp.versions.nuxt,
        'vue.version': nuxtApp.versions.vue,
        'app.route': route.name,
        'app.path': route.path,
        'app.query': route.query,
        ..._meta,
      },
    })
  })

  nuxtApp.hook('vue:error', (error, _instance, info) => {
    const _meta = meta()
    if (import.meta.dev) {
      console.error('vue:error', { error, _meta })
    }

    // report to sentry
    Sentry.captureException(error, {
      extra: {
        'error.info': info,
        'nuxt.version': nuxtApp.versions.nuxt,
        'vue.version': nuxtApp.versions.vue,
        'app.route': route.name,
        'app.path': route.path,
        'app.query': route.query,
        ..._meta,
      },
    })
  })
})

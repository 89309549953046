<template>
  <component
    :is="ErrorComponent"
    :statusCode="statusCode"
    :statusMessage="error?.statusMessage"
    :data="error?.data"
    :cause="error?.cause"
    :url="url"
  />
</template>

<script lang="ts" setup>
import type { NuxtError } from '#app'
import { Error500, Error403, Error404, Error500Dev } from '#components'

// Deliberately prevent reactive update when error is cleared
const props = defineProps({
  error: Object as () => NuxtError,
})

// TODO: extract to a separate utility
const stacktrace = (props?.error?.stack || '')
  .split('\n')
  .splice(1)
  .map((line) => {
    const text = line
      .replace('webpack:/', '')
      .replace('.vue', '.js') // TODO: Support sourcemap
      .trim()
    return {
      text,
      internal:
        (line.includes('node_modules') && !line.includes('.cache')) ||
        line.includes('internal') ||
        line.includes('new Promise'),
    }
  })
  .map(
    (i) =>
      `<span class="stack${i.internal ? ' internal' : ''}">${i.text}</span>`,
  )
  .join('\n')

// Error page props
const statusCode = Number(props?.error?.statusCode || 500)
const is404 = statusCode === 404
const is403 = statusCode === 403

const statusMessage =
  props?.error?.statusMessage ??
  (is404 ? 'Page Not Found' : 'Internal Server Error')

const description = (props?.error?.message || error.toString()).split(':', 2)[0]
const url = props?.error?.url || '/'

const cause =
  props?.error?.cause ||
  (typeof props?.error?.data === 'string' && props?.error?.data)

const stack =
  import.meta.dev && !is404
    ? props?.error?.statusMessage || `<pre>${stacktrace}</pre>`
    : undefined

const ErrorComponent = computed(() => {
  if (is403) return Error403
  if (is404) return Error404
  // if (import.meta.dev) return Error500Dev
  return Error500
})

const title = computed(
  () =>
    `${props?.error?.statusCode} - ${props?.error?.statusMessage} | Unity.org`,
)

useHead({
  title,
})
</script>
